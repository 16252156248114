import React, { useState, createContext } from "react";

// Create Context Object
export const CheckoutContext = createContext({});

// Create a provider for components to consume and subscribe to changes
export const CheckoutContextProvider = props => {
  const [price, setPrice] = useState(false);
  const [priceId, setPriceId] = useState(null);
  const [error, setError] = useState(null);


  return (
    <CheckoutContext.Provider value={{price, setPrice, priceId, setPriceId, error, setError}}>
      {props.children}
    </CheckoutContext.Provider>
  );
};