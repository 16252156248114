import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import axios from 'axios';
import Intercom from 'react-intercom';
import { IntercomAPI } from 'react-intercom';

import { UserContext } from "../../../context/userContext.js";
import { AppContext } from "../../../context/appContext.js";

import { useAuth0 } from "@auth0/auth0-react";

const privateTwitterHandle = "@" + process.env.REACT_APP_TWITTER_PRIVATE_HANDLE;

// eslint-disable-next-line
var stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginTop: "20px",
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 620,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '88%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#333',
    position: 'relative',
    backgroundSize: "180px 180px;",
    paddingTop: "40px",
    [breakpoints.up('md')]: {
      paddingTop: "0px",
      width: '100%',
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(147deg, #5c666b 0%, #333 64%)',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}));

export default function Home(props) {

  const {isLoading, setIsLoading, setSuccessMessage, error, setError, accessToken} = useContext(AppContext);
  const {user, isFollowing, subscriptionStatus, getTwitterFollowStatus} = useContext(UserContext);

  const { logout } = useAuth0();

  if(!user) {
    logout();
  }


  const initiateCheckout = async () => {
    setIsLoading(true);
    try {
      
      var response = await axios.get('/.netlify/functions/createCheckoutSession?accessToken='+accessToken);
      console.log(response.data);
      var sessionId = response.data.id;

      stripe.redirectToCheckout({
        sessionId
      }).then(function (result) {
        if(result.error) {
          setError(result.error.message);
          return;
        }
        
      });
    } catch(e) {
      console.log(e);
      setError("There was an error");
    }
    setIsLoading(false);
  };

  const followUs = async () => {
    setIsLoading(true);
    setError(null);
    try {
      var response = await axios.get('/.netlify/functions/followPrivateTwitter?accessToken='+accessToken);
       if(response.data.error == null) {
      //   await getTwitterFollowStatus();
      //   setSuccessMessage("Follow request sent");
      //   window.history.replaceState(null, null, window.location.pathname);

        window.location.replace("https://www.twitter.com/"+privateTwitterHandle.replace("@",""));

      } else {
        setError("There was an error. Please try again later.");
      }
      console.log(response.data);
    } catch(e) {
      console.log(e);
      setError("There was an error. Please try again later.");
    }
    setIsLoading(false);
  };

  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  const shadowStyles = useOverShadowStyles();

  const isActive = subscriptionStatus === "active" || subscriptionStatus === "trialing";

  const heading = isActive ? " ACTIVE" : 'INACTIVE';
  const body = isActive ? (isFollowing ? null : "You are not current following " + privateTwitterHandle + ". Click the button below to send us a follow request.") : null;
  const buttonText = isActive ? "Open "+privateTwitterHandle : "Sign up now";

  // intercom object to send into component below
  let intercomUser = {
    user_id: user.auth0_id,
    email: user.email,
    name: user.name,
    created_at: user.updated_at,
    custom_attributes: {
      stripe_id: user.stripe_id
    }
 };

  React.useEffect(() => {
    document.title = "Home - WSJ PTF";
    // Update the document title using the browser API
    IntercomAPI('trackEvent', 'logged in to PTF');

    if(new URLSearchParams(window.location.search).get("success") === "true") {
      setSuccessMessage("Payment Successful. Use the button below to follow " + privateTwitterHandle);
    }
  }, []);

  return (
    <div>
      
      <Card className={styles.root} style={{borderTop: isActive ? "5px lightgreen solid" : "5px red solid"}}>
        
        <CardMedia
          className={styles.media}
          image={
            process.env.PUBLIC_URL + '/sl_wsj_watermark__white.png'
          }
        />
        <CardContent style={{width: "100%"}}>
          <TextInfoContent
            classes={contentStyles}
            heading={`Twitter Subscription is ${heading}`}
            body={
              body
            }
          />
          
          
            <Button 
              onClick={() => { window.location.href = "https://twitter.com/"+privateTwitterHandle }} 
              style={{backgroundColor: "#1DA1F2", color: "#ffffff", paddingLeft: "16px",borderRadius: "100px", paddingRight: "16px", fontWeight:"bold"}}> <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23z" fill="#fff"/></svg
              >&nbsp;Go to Feed</Button>
          { isActive && 
            <Button 
              onClick={() => { window.location.href = `/.netlify/functions/redirectToStripePortal?accessToken=${accessToken}` }} 
              style={{backgroundColor: "#3333339c", color: "#ffffff", paddingLeft: "16px",borderRadius: "100px", paddingRight: "16px", marginTop: "15px", fontWeight:"bold"}}
              ><AccountCircleIcon/>&nbsp;Manage Account</Button>}
          {!isActive && 
            <Button 
              onClick={() => { window.location.href = "https://www.wallstjesus.com/twitter?open=true"; }} 
              style={{backgroundColor: "#3CD4A0", color: "#ffffff", paddingLeft: "16px",borderRadius: "100px", paddingRight: "16px", fontWeight:"bold"}}
              >Sign up now</Button>}

          {isActive && <div style={{fontSize: "10px", paddingTop:"10px"}}>Request to follow us on twitter <a href={"https://twitter.com/"+privateTwitterHandle}>{privateTwitterHandle}</a> and your follow request will be accepted ASAP. Twitter doesn’t allow us to do this automatically, but we have been notified and will take of it just as soon as we can.</div>}
          <br/><br/>
          {subscriptionStatus !== "active" && <Link to="/dashboard/migrate"><small>Click here if you have already purchased and need to sync your payment history</small></Link>}
        </CardContent>
      </Card>
      <Intercom appID="qf5jahxn" { ...intercomUser } />
    </div>
  );
}
