import React from 'react'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    button: {
        background: '#303030',
        borderRadius: '4px',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#fff',
        letterSpacing: '2px',
        textAlign: 'center',
        '&:hover': {
            background: '#383838'
        },
        '&:disabled': {
            color: '#fff'
        }
    },
    progress: { color: '#fff', marginLeft: '15px' }
})

export default function ProgressButton({ canClick = true, onClick, children, isLoading }) {
    const onClickHandler = React.useCallback(() => {
        if (isLoading) {
            return
        }

        onClick()
    }, [isLoading, onClick])

    const classes = useStyles()
    return (
        <Button
            disabled={!canClick}
            onClick={onClickHandler}
            fullWidth
            className={classes.button}
        >
            {children}
            {isLoading > 0 && (
                <CircularProgress size={18} className={classes.progress} />
            )}
        </Button>
    )
}
