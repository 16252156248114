import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from './serviceWorker';
import {AppContextProvider} from "./context/appContext.js";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_NATIVE_APP_CLIENT_ID;
const auth0RedirectUrl = process.env.REACT_APP_AUTH0_REDIRECT_URL;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

ReactDOM.render(
  <React.StrictMode>
     <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          redirectUri={window.location.href}
          audience={auth0Audience}
          cacheLocation="localstorage"
          //useRefreshTokens={true}
        >
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </Auth0Provider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
