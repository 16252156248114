import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { LoadingSpinner } from "./../helpers/LoadingSpinner.js";

// styles
import useStyles from "./styles";

function Login(props) {
  var classes = useStyles();

  const { loginWithRedirect, isAuthenticated, isLoading, user, getAccessTokenSilently, handleRedirectCallback } = useAuth0();

  useEffect(() => {
    if(isLoading)
    return; 

    if(!isAuthenticated) {
      loginWithRedirect({connection: 'twitter', audience:"hasura"});
    } else {
      console.log("authenticated")

      
      close();
    }
  }, [isLoading, isAuthenticated])

  

  

  const close = async () => {
    
    window.opener.popupCallback(true); //Call callback function 
    window.close();
  }

  if (isLoading) {
    return (<LoadingSpinner />);
  }

  return (
    <Grid container className={classes.container}>
      
    </Grid>
  );
}

export default withRouter(Login);