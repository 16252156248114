import React, {useContext} from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import withWidth from "@material-ui/core/withWidth";
import Alert from '@material-ui/lab/Alert';

import Navigation from "./navigation.js";
import Home from "./pages/home.js";
import Migrate from "./pages/migrate.js";
import PaymentSuccess from "./pages/payment-success.js";

import { AppContext } from "../../context/appContext.js";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

function Dashboard(props) {
  const {error, successMessage}  = useContext(AppContext);
  let { path } = useRouteMatch();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />-
      <Navigation />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {!!error && <div><Alert variant="filled" severity="error"><b>Error:</b> {error}</Alert><br /></div>}
        {!!successMessage && (<div><Alert variant="filled" severity="success">{successMessage}</Alert><br /></div>)}
        <Switch>
          <Route exact path={path}>
            <Home />
          </Route>
          <Route path={`${path}/migrate`}>
            <Migrate />
          </Route>
          <Route path={`${path}/payment-success`}>
            {/* <PaymentSuccess /> */}
            <Redirect to="/dashboard?success=true" />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default withWidth()(Dashboard);
