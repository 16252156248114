import { Typography, makeStyles } from '@material-ui/core'
import { TrendingUp as SuccessIcon } from '@material-ui/icons'
import React from 'react'

import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function FinishStep({ isOpen, onClose, title, message }) {
    const classes = useStyles()
    // get url param for is_embed
    const isEmbed = window.location.href.indexOf('is_embed=true') > -1;

    return (
        <Product.Dialog isOpen={isOpen} onClose={onClose}>
            <Product.Header title={title} onClose={onClose} condensed>
                <SuccessIcon className={classes.headerResponseIcon} />
            </Product.Header>
            <Product.Content>
                <Product.Description>Success!</Product.Description>
                <Typography style={{ marginTop: '15px' }}>
                    {message}
                </Typography>
            </Product.Content>
            <Product.Actions>
                {!isEmbed && <ProgressButton onClick={onClose}>MANAGE MY ACCOUNT</ProgressButton>}
            </Product.Actions>
        </Product.Dialog>
    )
}
