import {
    FormHelperText,
    Input,
    MenuItem,
    Select,
    Typography,
    makeStyles
} from '@material-ui/core'
import { CreditCard as CreditCardIcon } from '@material-ui/icons'
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js'
import axios from 'axios'
import { getCodeList } from 'country-list'
import sortBy from 'lodash/sortBy'
import React, { useContext, useState } from 'react'
import { useMutation } from 'react-query'

import { useErrorContext } from './ErrorContext'
import Coupon from './Coupon'
import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'
import styles from './styles'
import { useValidation } from './validation'

import { AppContext } from "../../../context/appContext";

const stripeStyles = {
    fontFamily: 'Montserrat',
    fontSize: '16px'
}

const stripeOptions = { style: { base: stripeStyles } }

const countries = sortBy(
    Object.entries(getCodeList()).map(([code, name]) => ({ code, name })),
    x => x.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
)

const validationSchema = {
    address: 'required',
    city: 'required',
    country: 'required',
    hasCardNumber: 'required',
    hasCvc: 'required',
    hasExpiry: 'required',
    name: 'required',
    zip: 'required'
}

const useStyles = makeStyles(styles)

export default function BillingStep({
    price,
    priceId,
    values,
    showCoupon,
    coupon,
    validateCoupon,
    onCouponValidated,
    onChangeStep,
    isAuthenticated
}) {
    const stripe = useStripe()
    const elements = useElements()

    const {isLoading, setIsLoading, setSuccessMessage, error, setError, accessToken} = useContext(AppContext);


    const [billing, setBilling] = React.useState({
        address: '',
        city: '',
        country: 'us',
        hasCardNumber: false,
        hasCvc: false,
        hasExpiry: false,
        name: '',
        zip: ''
    })

    const [errorText, setErrorText] = useState(null)
    const [stripeClientSecret, setStripeClientSecret] = useState(null);

    React.useEffect(() => {
        console.log("isAuthenticated",isAuthenticated, priceId)
       if(!isAuthenticated && priceId) {
            //initiateCheckout();
       }
       
       if(isAuthenticated && accessToken && priceId) {
            //initiateCheckout();
       }
    }, [accessToken, priceId])

    const onChangeValue = React.useCallback((name, value) => {
        setBilling(billing => ({
            ...billing,
            [name]: value
        }))
    }, [])

    const onChange = React.useCallback(e => {
        const { name, value } = e.target
        onChangeValue(name, value)
    }, [])

    const onSubmit = async () => {
        // if (!validate(billing)) {
        //     return
        // }
        setIsLoading(true);
        try {
            var response = await axios.post('/.netlify/functions/createCustomerCheckout', {
                accessToken,
                priceId: priceId,
                email: values.email,
                promoCode: coupon
            });
            console.log(response.data);


            const cardElement = elements.getElement(CardNumberElement)

            // const { error, token } = await stripe.createToken(cardElement, {
            //     name: billing.name,
            //     address_line1: billing.address,
            //     address_city: billing.city,
            //     address_state: billing.state,
            //     address_zip: billing.zip,
            //     address_country: billing.country
            // })

            // if (error) {
            //     //setAsyncError({ error })
            //     return
            // }

            // Create payment method and confirm payment intent.
            stripe.confirmCardPayment(response.data.clientSecret, {
                payment_method: {
                card: cardElement,
                billing_details: {
                    address: {
                        postal_code: billing.zip
                    }
                  },
                },
                setup_future_usage: 'off_session'
            }).then((result) => {
                if(result.error) {
                setErrorText(result.error.message);
                } else {
                // Successful subscription payment
                console.log("payment success")
                onChangeStep()
                }
                setIsLoading(false);
            });
        } catch (error) {
            //setAsyncError({ error })
            setIsLoading(false);
        }
        
    }

    const classes = useStyles()

    return (
        <>
            <Product.Content>
                <Product.Description>Payment Details</Product.Description>

                <div className={classes.cardNumberContainer}>
                    <CreditCardIcon className={classes.cardIcon} />
                    <CardNumberElement
                        options={stripeOptions}
                        onChange={e =>
                            onChangeValue('hasCardNumber', e.complete)
                        }
                    />
                </div>

                <div className={classes.billingDetailsSection}>
                    <div className={classes.cardDetailsContainer}>
                        <CardExpiryElement
                            options={stripeOptions}
                            onChange={e =>
                                onChangeValue('hasExpiry', e.complete)
                            }
                        />
                    </div>
                    <div className={classes.cardDetailsContainer}>
                        <CardCvcElement
                            options={stripeOptions}
                            onChange={e => onChangeValue('hasCvc', e.complete)}
                        />
                    </div>
                </div>

                <Input
                    fullWidth
                    placeholder="Name on Card"
                    className={classes.input}
                    name="name"
                    onChange={onChange}
                    value={billing.name}
                />

<Input
                    fullWidth
                    placeholder="Billing Zip"
                    className={classes.input}
                    name="zip"
                    onChange={onChange}
                    value={billing.zip}
                />        
{/* 
                <Typography className={classes.billingSubheading}>
                    BILLING DETAILS
                </Typography>

                <div className={classes.billingDetailsSection}>
                    <Input
                        placeholder="Address"
                        className={classes.billingAddress}
                        name="address"
                        onChange={onChange}
                        value={billing.address}
                    />
                    <Input
                        placeholder="Zip"
                        className={classes.billingZip}
                        name="zip"
                        onChange={onChange}
                        value={billing.zip}
                    />
                </div>

                <div className={classes.billingDetailsSection}>
                    <Input
                        placeholder="City"
                        className={classes.billingCity}
                        name="city"
                        onChange={onChange}
                        value={billing.city}
                    />
                    <Input
                        placeholder="State"
                        className={classes.billingState}
                        name="state"
                        onChange={onChange}
                        value={billing.state}
                    />
                </div> */}

                {/* <Select
                    fullWidth
                    placeholder="Country"
                    className={classes.billingCountry}
                    name="country"
                    onChange={onChange}
                    value={billing.country}
                >
                    {countries.map(c => (
                        <MenuItem key={c.code} value={c.code}>
                            {c.name}
                        </MenuItem>
                    ))}
                </Select> */}

{showCoupon && (
                    <Coupon
                        coupon={coupon}
                        validateCoupon={validateCoupon}
                        onCouponValidated={onCouponValidated}
                    />
                )}

                {/* {anyErrors && (
                    <FormHelperText error style={{ marginTop: 20 }}>
                        Please fill in all fields
                    </FormHelperText>
                )} */}

                {errorText && (
                    <FormHelperText error style={{ marginTop: 20 }}>
                        {errorText}
                    </FormHelperText>
                )}
            </Product.Content>
            <Product.Actions>
                <ProgressButton onClick={onSubmit} isLoading={isLoading}>
                    SUBMIT PAYMENT
                </ProgressButton>
            </Product.Actions>
        </>
    )
}
