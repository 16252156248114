import React from 'react'
import BillingStep from './BillingStep'
import FinishDialog from './FinishDialog'
import PaymentPlanStep from './MasterCoursePaymentPlanStep'
import * as Product from './ProductDialog'
import StartCheckoutStep from './StartCheckoutStep'

function getBasePrice(plan) {
    if (plan === 'plan-6') {
        return 4400
    }

    return 4000
}

function getCouponDiscount(coupon) {


    if (coupon === '400MASTER') {
        return 400
    }

    if (coupon === 'CENTERPOINT2023') {
        return 2000
      }

    return null
}

function getText({ plan, discount = 0 }) {
    if (plan === 'plan-6') {
        return `$1,000 Down Payment of $${getBasePrice(plan) - discount}`
    }

    var price = getBasePrice(plan) - discount;

    var priceWithCommas = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return `$${ priceWithCommas }`
}

function getSubtitle(plan, step) {
    if (step === 'start' || step === 'finish') {
        return null
    }

    if (plan === 'plan-6') {
        return '6-Month Payment Plan'
    }

    return 'Single Payment'
}

export default function MasterCourseCheckout({ isOpen, onClose, isAuthenticated, priceId }) {
    const [step, setStep] = React.useState('start')
    const [values, setValues] = React.useState({
        plan: 'one-time'
    })
    const [coupon, setCoupon] = React.useState(null);

    const onUpdate = React.useCallback(update => {
        setValues(values => ({
            ...values,
            ...update
        }))
    }, [])

    const onChangeStep = React.useCallback(
        update => {
            setValues(values => ({
                ...values,
                ...update
            }))

            if (step === 'start') {
                setStep('billing')
                return
            }

            if (step === 'payment-plan') {
                setStep('billing')
                return
            }

            if (step === 'billing') {
                setStep('finish')
                return
            }
        },
        [step]
    )

    const renderedStep = React.useMemo(() => {
        const props = {
            product: 'master-course',
            values,
            onUpdate,
            onChangeStep,
            isAuthenticated,
            priceId,
            validateCoupon: (coupon) => {
                if (getCouponDiscount(coupon)) {
                    return coupon;
                }
                return null;
            },
            onCouponValidated: setCoupon,
            coupon,
            showCoupon: true
        }

        if (step === 'start') {
            return <StartCheckoutStep {...props} />
        }

        if (step === 'payment-plan') {
            return <PaymentPlanStep {...props} />
        }

        if (step === 'billing') {
            return <BillingStep {...props} />
        }
    }, [values, step, coupon])

    if (step === 'finish') {
        return (
            <FinishDialog
                title="The Sang Lucci Master Course"
                isOpen={isOpen}
                onClose={onClose}
                message="You've officially signed up for the Sang Lucci Master Course. Please check your email for important instructions and next steps."
            />
        )
    }

    return (
        <Product.Dialog isOpen={isOpen} onClose={onClose}>
            <Product.Header
                title="The Sang Lucci Master Course"
                subtitle={getSubtitle(values.plan, step)}
                onClose={onClose}
                condensed
            >
                <Product.Price
                    plan={values.plan}
                    base={getBasePrice(values.plan)}
                    discount={getCouponDiscount(coupon)}
                    getText={args => getText({ ...args, plan: values.plan })}
                    smaller={values.plan !== 'one-time'}
                />
            </Product.Header>
            {renderedStep}
        </Product.Dialog>
    )
}
