import React from 'react'
import BillingStep from './BillingStep'
import FinishDialog from './FinishDialog'
import * as Product from './ProductDialog'
import StartCheckoutStep from './StartCheckoutStep'
import StartBitPayStep from './StartBitPayStep'

function getBasePrice(plan) {
    return 749
}

function getCouponDiscount(coupon) {
    coupon = coupon && coupon.toUpperCase();
    if (coupon === 'MINDRIGHT') {
        return 250
    }
    if (coupon === 'UNSCRIPTED') {
        return 100
    }
}

function getText({ plan, discount = 0 }) {
    var price = getBasePrice(plan) - discount;

    var priceWithCommas = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return `$${ priceWithCommas }`
}

function getSubtitle(plan, step) {
    if (step === 'start' || step === 'finish') {
        return null
    }


    return 'Single Payment'
}

export default function PsychologyCourseCheckout({ isOpen, onClose, isAuthenticated, priceId }) {
    const [step, setStep] = React.useState('start')
    const [values, setValues] = React.useState({
        plan: 'one-time'
    })
    const [coupon, setCoupon] = React.useState(null);

    const onUpdate = React.useCallback(update => {
        setValues(values => ({
            ...values,
            ...update
        }))
    }, [])

    const onChangeStep = React.useCallback(
        update => {
            setValues(values => ({
                ...values,
                ...update
            }))

            if (step === 'start') {
                setStep('billing')
                return
            }

            if (step === 'billing') {
                setStep('finish')
                return
            }
        },
        [step]
    )

    const renderedStep = React.useMemo(() => {
        const props = {
            product: 'clear-mind-trading-course',
            values,
            onUpdate,
            onChangeStep,
            isAuthenticated,
            priceId,
            validateCoupon: (coupon) => {
                if (getCouponDiscount(coupon)) {
                    return coupon;
                }
                return null;
            },
            onCouponValidated: setCoupon,
            coupon,
            showCoupon: true
        }

        if (step === 'start') {
            return <StartCheckoutStep {...props} />
        }

        if (step === 'billing') {
            return <BillingStep {...props} />
        }
    }, [values, step, coupon])

    if (step === 'finish') {
        return (
            <FinishDialog
                title="Clear Mind Trading Course"
                isOpen={isOpen}
                onClose={onClose}
                message="You've officially signed up for the Sang Lucci Clear Mind Trading Course. Please check your email for important instructions and next steps."
            />
        )
    }

    return (
        <Product.Dialog isOpen={isOpen} onClose={onClose}>
            <Product.Header
                title="Clear Mind Trading Course"
                subtitle={getSubtitle(values.plan, step)}
                onClose={onClose}
                condensed
            >
                <Product.Price
                    plan={values.plan}
                    base={getBasePrice(values.plan)}
                    discount={getCouponDiscount(coupon)}
                    getText={args => getText({ ...args, plan: values.plan })}
                    smaller={values.plan !== 'one-time'}
                />
            </Product.Header>
            {renderedStep}
        </Product.Dialog>
    )
}
