import { Elements as ElementsProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import React, {useContext} from 'react'
import { ReactQueryConfigProvider } from 'react-query'

//import { ErrorContextProvider, useErrorContext } from './ErrorContext'
import IntroToOptionsCheckout from './IntroToOptionsCheckout'
import MasterCourseCheckout from './MasterCourseCheckout'
import CryptoCourseCheckout from './CryptoCourseCheckout'
import PrivateTwitterFeedCheckout from './PrivateTwitterFeedCheckout'
import ProcessingErrorDialog from './ProcessingErrorDialog'
import TradingThePostCheckout from './TradingThePostCheckout'
import PsychologyCourseCheckout from './PsychologyCourse'

import { useAuth0 } from "@auth0/auth0-react";
import { CheckoutContext } from '../context'


const Checkout = ({isAuthenticated}) => {
    const [stripe, setStripe] = React.useState(null)
        const [isOpen, setIsOpen] = React.useState(false)
        const [product, setProduct] = React.useState(null);
        const [price, setPrice] = React.useState(null);
        const [priceId, setPriceId] = React.useState(null);
        const [recurring, setRecurring] = React.useState(null);
        const [isTest, setIsTest] = React.useState(false);

        const { loginWithRedirect, getAccessTokenSilently, user } = useAuth0();

        console.log("checkout authenticated: " + isAuthenticated)
        console.log(user)

        //const { error, isPersistent, setAsyncError } = useErrorContext()

        const onClose = React.useCallback(() => {
            setIsOpen(false)
        }, [])

        // React.useImperativeHandle(ref, () => ({
        //     open: () => {
        //         setIsOpen(true)
        //     }
        // }))

        React.useEffect(() => {
            let searchParams = new URLSearchParams(window.location.search)

            const product = searchParams.get("product")
            const priceId = searchParams.get("priceId")
            const price = searchParams.get("price")
            const recurring = searchParams.get("recurring")
            const test = searchParams.get("test")
            
            setProduct(product);
            setPriceId(priceId)
            setPrice(price);
            setRecurring(recurring);
            setIsTest(test === "true");

            setStripe(
                loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then(
                    v => v,
                    error => {
                        // setAsyncError({
                        //     error,
                        //     isErrorPersistent: true
                        // })
                        console.log(error)
                    }
                )
            )
        }, [])

        const onError = React.useCallback(error => {
            //setAsyncError({ error })
            console.log(error)
        }, [])

        const reactQueryConfig = React.useMemo(
            () => ({
                retry: (failureCount, error) => {
                    return !error.response && failureCount <= 3
                },
                retryDelay: attempt => attempt * 1000,
                queries: {
                    onError
                },
                mutations: {
                    onError
                }
            }),
            []
        )

        const renderedProduct = React.useMemo(() => {
            // if (error) {
            //     return <ProcessingErrorDialog isIntermittent={isPersistent} />
            // }

            const props = {
                onClose,
               product,
               isAuthenticated,
               priceId,
               price,
               recurring,
               isTest,
               isOpen: true
            }

            console.log(product);
        

            if (product === 'master-course') {
                return <MasterCourseCheckout {...props} />
            }

            if (product === 'crypto-course') {
                return <CryptoCourseCheckout {...props} />
            }

            if (product === 'clear-mind-trading-course') {
                return <PsychologyCourseCheckout {...props} />
            }

            // if (
            //     product === 'trading-the-post' ||
            //     product === 'trading-the-post-premium'
            // ) {
            //     return <TradingThePostCheckout {...props} />
            // }

            // if (product === 'intro-to-options') {
            //     return <IntroToOptionsCheckout {...props} />
            // }

            if (product === 'private-twitter') {
                return <PrivateTwitterFeedCheckout {...props} />
            }

            //throw new Error(`Unknown product=${product}`)
        }, [ isOpen, price, product])

        return (
        <div>
            <ElementsProvider
                stripe={stripe}
                options={{
                    fonts: [
                        {
                            cssSrc:
                                'https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap'
                        }
                    ],
                    locale: 'en'
                }}
            >
                <ReactQueryConfigProvider config={reactQueryConfig}>
                    {renderedProduct}
                </ReactQueryConfigProvider>
            </ElementsProvider>
        </div>
        )
}

export default Checkout;
