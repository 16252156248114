import React, { useState, createContext } from "react";
import {LoadingSpinner} from "./../components/helpers/LoadingSpinner.js";

// Create Context Object
export const AppContext = createContext({});

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState("");


  return (
    <AppContext.Provider value={{isLoading, setIsLoading, successMessage, setSuccessMessage, error, setError, accessToken, setAccessToken}}>
      {isLoading && <LoadingSpinner/>}
      <div style={{display: isLoading ? "none" : "block"}}>
        {props.children}
      </div>
    </AppContext.Provider>
  );
};