import {
    FormControlLabel,
    makeStyles,
    Radio,
    RadioGroup
} from '@material-ui/core'
import React from 'react'

import Coupon from './Coupon'
import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'
import styles from './styles'

const useStyles = makeStyles(styles)

function getDescription(plan) {
    if (plan === 'plan-6') {
        return `After your Down Payment, your remaining tuition is broken up over 6 monthly payments.`
    }

    return `Choose the payment schedule that best fits your needs.`
}

function validateCoupon(coupon) {
    if (coupon === 'SLMC250OFF') {
        return coupon
    }

    if (coupon === 'SLMC500OFF') {
        return coupon
    }

    if (coupon === 'SAVE750') {
        return coupon
    }

    return null
}

function PaymentPlan({ value, children, disabled }) {
    const classes = useStyles()
    return (
        <FormControlLabel
            className={classes.radioContainer}
            control={<Radio className={classes.radio} />}
            value={value}
            label={children}
            disabled={disabled}
        />
    )
}

export default function PaymentPlanStep({ values, onChangeStep, onUpdate }) {
    const onChangePlan = React.useCallback(
        e =>
            onUpdate({
                plan: e.target.value
            }),
        [values]
    )

    const onCouponValidated = React.useCallback(
        coupon => onUpdate({ coupon }),
        [values]
    )

    const onSubmit = React.useCallback(() => {
        onChangeStep(values)
    }, [values])

    const classes = useStyles()
    return (
        <>
            <Product.Content>
                <Product.Description>
                    {getDescription(values.plan)}
                </Product.Description>
                <RadioGroup
                    defaultValue={'one-time'}
                    value={values.plan}
                    className={classes.radioGroup}
                    onChange={onChangePlan}
                >
                    <PaymentPlan value="one-time">
                        One-Time Purchase
                    </PaymentPlan>
                    <PaymentPlan value="plan-6">
                        6-Month Payment Plan
                    </PaymentPlan>
                </RadioGroup>
                <Coupon
                    coupon={values.coupon}
                    validateCoupon={validateCoupon}
                    onCouponValidated={onCouponValidated}
                />
            </Product.Content>
            <Product.Actions>
                <ProgressButton
                    canClick={values.coupon !== false}
                    onClick={onSubmit}
                >
                    NEXT
                </ProgressButton>
            </Product.Actions>
        </>
    )
}
