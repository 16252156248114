import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache, gql } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { useAuth0 } from "@auth0/auth0-react";

import { AppContext } from "./context/appContext.js";
import { UserContextProvider } from "./context/userContext.js";

import LoginPage from "./components/login/login.js";
import LoginPageTwitter from "./components/login/twitter-login-popup.js";
import Dashboard from "./components/dashboard/dashboard.js";
import Checkout from "./components/checkout/components/index"


import { LoadingSpinner } from "./components/helpers/LoadingSpinner.js";

const graphQlUrl = process.env.REACT_APP_GRAPHQL_URL;

export default function App() {
  const context = useContext(AppContext);
  const { accessToken, setAccessToken } = context;
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, logout, user } = useAuth0();
  console.log(isAuthenticated, user);

  if (isLoading) {
    return (<LoadingSpinner />);
  }

  if (isAuthenticated) {
    const getAccessToken = async () => {
      // getTokenSilently() returns a promise
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
        console.log(token);
      } catch (e) {
        console.log(e);
      }
    };
    getAccessToken();
  }

  const httpLink = new HttpLink({
    uri: graphQlUrl
  });

  const authLink = setContext((_, { headers }) => {
    const token = accessToken;
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    } else {
      return {
        headers: {
          ...headers
        }
      };
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return (
    <Router>
      <ApolloProvider client={client}>
          <div>
            <Switch>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/checkout">
                <Checkout isAuthenticated={isAuthenticated}/>
              </Route>
              <Route path="/twitter-login">
                <LoginPageTwitter />
              </Route>
              <Route path="/callback">
                <Redirect to="/dashboard" />
              </Route>
              <PrivateRoute path="/dashboard" isAuthenticated={isAuthenticated}>
                <UserContextProvider>
                  <Dashboard />
                </UserContextProvider>
              </PrivateRoute>
              <Redirect to="/dashboard" />
            </Switch>
          </div>
      </ApolloProvider>
    </Router>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not authenticated yet.
function PrivateRoute({ isAuthenticated, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
