import { Button, Typography, makeStyles } from '@material-ui/core'
import { SentimentVeryDissatisfied as ErrorIcon } from '@material-ui/icons'
import React from 'react'

import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'
import styles from './styles'

const useStyles = makeStyles(styles)

export default function ProcessingErrorDialog({ isIntermittent }) {
    const [isOpen, setIsOpen] = React.useState(true)

    const onClose = React.useCallback(() => {
        setIsOpen(false)
    }, [])

    const classes = useStyles()

    const feedback = React.useMemo(() => {
        if (isIntermittent) {
            return (
                <Typography style={{ marginTop: '15px' }}>
                    One of the resources that we require to process your
                    checkout, failed to load, probably due to a network error.
                    Please refresh the page and try again. If the error
                    persists, please email
                    <strong>
                        {' '}
                        <a
                            href="mailto:spencer@sanglucci.com"
                            className={classes.mailto}
                        >
                            spencer@sanglucci.com
                        </a>{' '}
                    </strong>
                    for further assistance.
                </Typography>
            )
        }

        return (
            <Typography style={{ marginTop: '15px' }}>
                We apologize for the inconvenience. Please email
                <strong>
                    {' '}
                    <a
                        href="mailto:spencer@sanglucci.com"
                        className={classes.mailto}
                    >
                        spencer@sanglucci.com
                    </a>{' '}
                </strong>
                for further assistance.
            </Typography>
        )
    }, [isIntermittent])

    return (
        <Product.Dialog isOpen={isOpen} onClose={onClose}>
            <Product.Header onClose={onClose}>
                <ErrorIcon className={classes.headerResponseIcon} />
            </Product.Header>
            <Product.Content>
                <Product.Description>
                    There's been an error.
                </Product.Description>
                {feedback}
            </Product.Content>
            <Product.Actions>
                <ProgressButton onClick={onClose}>CLOSE</ProgressButton>
            </Product.Actions>
        </Product.Dialog>
    )
}
