import { Button, makeStyles, TextField } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import React from 'react'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function Coupon({ coupon, validateCoupon, onCouponValidated }) {
    const [isCouponFieldEnabled, setIsCouponFieldEnabled] = React.useState(
        false
    )
    const [isCouponValid, setIsCouponValid] = React.useState(undefined)
    const [couponValue, setCouponValue] = React.useState('')

    const onChange = React.useCallback(e => {
        setCouponValue(e.target.value.toUpperCase())
        setIsCouponValid(undefined)
        onCouponValidated(undefined)
    }, [])

    const onClear = React.useCallback(() => {
        setCouponValue('')
        setIsCouponValid(undefined)
        onCouponValidated(undefined)
    }, [])

    const onSubmit = React.useCallback(() => {
        const foundCoupon = validateCoupon(couponValue)
        setIsCouponValid(!!foundCoupon)
        onCouponValidated(foundCoupon ? foundCoupon : false)
    }, [couponValue])

    const classes = useStyles()

    const renderedCouponButton = React.useMemo(() => {
        if (isCouponValid === false && couponValue !== '') {
            return (
                <Button className={classes.couponInputBtn} onClick={onClear}>
                    CLEAR
                </Button>
            )
        }

        if (!coupon) {
            return (
                <Button className={classes.couponInputBtn} onClick={onSubmit}>
                    SUBMIT
                </Button>
            )
        }

        return <CheckIcon />
    }, [isCouponValid, coupon, couponValue])

    if (!isCouponFieldEnabled) {
        return (
            <Button
                fullWidth
                className={classes.couponBtn}
                onClick={() => setIsCouponFieldEnabled(true)}
            >
                I have a Coupon
            </Button>
        )
    }

    return (
        <TextField
            fullWidth
            type="text"
            error={isCouponValid === false}
            helperText={
                isCouponValid === false && 'Please enter a valid coupon'
            }
            InputProps={{
                disableUnderline: true,
                endAdornment: renderedCouponButton
            }}
            placeholder="Enter Coupon"
            className={classes.couponInput}
            onChange={onChange}
            value={couponValue}
        />
    )
}
