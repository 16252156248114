import {
    Dialog as MuiDialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    makeStyles
} from '@material-ui/core'
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { arrayOf } from 'prop-types'
import React from 'react'

import styles from './styles'

const useStyles = makeStyles(styles)

function normalize(n) {
    if (n - Math.floor(n) > 0) {
        return n.toFixed(2)
    }
    return n.toFixed(0)
}

export function Price({ base, discount, monthly, yearly, plan, getText, smaller = false }) {
    const classes = useStyles()

    const getDisplayText = function () {
        if (getText) {
            return getText({ normalize, plan, base, discount })
        }

        let price = base

        if (discount) {
            price = discount < 1 ? price * discount : price - discount
        }

        if (monthly) {
            return `$${price}/mo`
        }

        if(yearly) {
            return `$${price}/year`
        }

        return `$${price}`
    }

    return (
        <Typography className={clsx(classes.price, smaller && classes.priceSmaller)}>
            {discount > 0 && (
                <span className={classes.priceStriked}>{`$${base}`}</span>
            )}
            {getDisplayText()}
        </Typography>
    )
}

export function Dialog({ isOpen, onClose, children }) {
    const classes = useStyles()
    return (
        <MuiDialog
            disableBackdropClick
            onClose={onClose}
            open={isOpen}
            classes={{ paper: classes.dialogPaper }}
            BackdropProps={{ classes: { root: classes.backdrop } }}
            className={classes.root}
            fullWidth
            maxWidth="xs"
        >
            {children}
        </MuiDialog>
    )
}

export function Header({ children, title, subtitle, condensed, onClose }) {
    const classes = useStyles()
    // get url param for is_embed
    const isEmbed = window.location.href.indexOf('is_embed=true') > -1;
    return (
        <DialogTitle
            className={clsx([
                classes.header,
                condensed && classes.headerCondensed
            ])}
        >
            {!isEmbed && <IconButton onClick={() => window.history.go(-1)} className={classes.backButton}>
                <ArrowBackIcon />
            </IconButton>}
            {title && (
                <Typography className={classes.title}>{title}</Typography>
            )}
            {/* <IconButton onClick={onClose} className={classes.closeButton}>
                <CloseIcon />
            </IconButton> */}
            {children}
            {subtitle && (
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            )}
        </DialogTitle>
    )
}

export function Content({ children }) {
    const classes = useStyles()
    return <DialogContent className={classes.content}>{children}</DialogContent>
}

export function Description({ children }) {
    const classes = useStyles()
    return <Typography className={classes.paragraph}>{children}</Typography>
}

export function Actions({ children }) {
    const classes = useStyles()
    return <DialogActions className={classes.footer}>{children}</DialogActions>
}
