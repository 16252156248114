import React from 'react'
import { TextField, makeStyles } from '@material-ui/core'

import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'
import { useValidation } from './validation'

import styles from './styles'

const useStyles = makeStyles(styles)

export default function StartCheckoutStep({ onChangeStep }) {
    const [values, setValues] = React.useState({
        email: '',
        confirmEmail: ''
    })

    const { validate, errors, ifError, reset } = useValidation({
        email: ['required', 'email'],
        confirmEmail: [
            'required',
            {
                type: 'match',
                property: 'email'
            }
        ]
    })

    const onChange = React.useCallback(e => {
        const { name, value } = e.target
        setValues(values => ({
            ...values,
            [name]: value
        }))
        reset()
    }, [])

    const onSubmit = React.useCallback(() => {
        if (!validate(values)) {
            return
        }

        onChangeStep({
            email: values.email
        })
    }, [values])

    const classes = useStyles()
    return (
        <>
            <Product.Content>
                <Product.Description>Enter Email</Product.Description>
                <TextField
                    fullWidth
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    className={classes.input}
                    onChange={onChange}
                    value={values.email}
                    error={errors.email}
                    helperText={ifError('email', 'Please fill-in your e-mail')}
                />

                <TextField
                    fullWidth
                    placeholder="Confirm e-mail"
                    type="email"
                    name="confirmEmail"
                    className={classes.input}
                    onChange={onChange}
                    value={values.confirmEmail}
                    error={errors.confirmEmail}
                    helperText={ifError(
                        'confirmEmail',
                        'Please confirm your e-mail'
                    )}
                />
            </Product.Content>
            <Product.Actions>
                <ProgressButton onClick={onSubmit}>NEXT</ProgressButton>
            </Product.Actions>
            
        </>
    )
}