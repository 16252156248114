import React from 'react'
import BillingStep from './BillingStep'
import FinishDialog from './FinishDialog'
import * as Product from './ProductDialog'
import StartCheckoutStep from './StartCheckoutStep'
import TwitterLoginStep from './TwitterLoginStep'

export default function PrivateTwitterFeedCheckout({ isOpen, price, priceId, recurring }) {
    const [step, setStep] = React.useState('link')
    const [values, setValues] = React.useState({
        email: null,
        coupon: null,
        metadata: {
            twitterUsername: null
        }
    })

    const onClose = React.useCallback(update => {
        window.location.href = "https://ptf.wallstjesus.com";
    }, [])

    const onUpdate = React.useCallback(update => {
        setValues(values => ({
            ...values,
            ...update
        }))
    }, [])

    const onChangeStep = React.useCallback(
        update => {
            setValues(values => ({
                ...values,
                ...update
            }))

            if (step === 'start') {
                setStep('link')
                return
            }

            if (step === 'link') {
                setStep('billing')
                return
            }

            if (step === 'billing') {
                setStep('finish')
                return
            }
        },
        [step]
    )

    const renderedStep = React.useMemo(() => {
        const props = {
            product: 'private-twitter-feed',
            values,
            onUpdate,
            onChangeStep, 
            priceId
        }

        if (step === 'start') {
            return <StartCheckoutStep {...props} />
        }

        if (step === 'billing') {
            return <BillingStep {...props} />
        }

        if (step === 'link') {
            return <TwitterLoginStep {...props} />
        }
    }, [values, step, priceId])

    if (step === 'finish') {
        return (
            <FinishDialog
                title="Private Twitter Feed"
                isOpen={isOpen}
                onClose={onClose}
                message={
                    <>
                        Payment has successfully been processed. Click the button below to manage your account.
                    </>
                }
            />
        )
    }

    return (
        <Product.Dialog isOpen={isOpen} onClose={onClose}>
            <Product.Header
                title="Private Twitter Feed"
                onClose={onClose}
                condensed
            >
                <Product.Price base={price} monthly={recurring==="monthly"} yearly={recurring==="yearly"} />
            </Product.Header>
            {renderedStep}
        </Product.Dialog>
    )
}
