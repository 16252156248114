import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import axios from 'axios';
import Intercom from 'react-intercom';
import { IntercomAPI } from 'react-intercom';

import { UserContext } from "../../../context/userContext.js";
import { AppContext } from "../../../context/appContext.js";

const privateTwitterHandle = "@" + process.env.REACT_APP_TWITTER_PRIVATE_HANDLE;

// eslint-disable-next-line
var stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginTop: "20px",
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 500,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}));

export default function Migrate(props) {

  const { setSuccessMessage, error, setError, accessToken} = useContext(AppContext);
  const {user, isFollowing, subscriptionStatus, getTwitterFollowStatus} = useContext(UserContext);

  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  const tryMigrateEmail = async () => {
    setIsLoading(true);
    try {
      
      var response = await axios.get(`/.netlify/functions/tryMigrateEmail?email=${email}&accessToken=${accessToken}`);
      console.log(response.data);
      
      var message = response.data.message || response.data.error;
      setMessage(message)
     
    } catch(e) {
      console.log(e);
      setMessage("There was an error");
    }
    setIsLoading(false);
  };

  const onEmailInputChange = (event) => {
    setEmail(event.target.value);
  }

  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  React.useEffect(() => {
    document.title = "Migrate - WSJ PTF";
  }, []);

  var isError = message != "" && message.toLowerCase().includes("error");

  return (
    <div>
      
      <Card className={styles.root} style={{borderTop: "5px #333333 solid"}}>
        <CardContent style={{width: "100%"}}>
          <TextInfoContent
            classes={contentStyles}
            heading={"Confirm Email"}
            body={
              "Please enter the email you used to sign up for the Private Twitter Feed or the Steamroom. We'll use this to sync our system with your payment records."
            }
          />

          <TextField onChange={onEmailInputChange} fullWidth id="outlined-basic" label="Enter email" variant="outlined" />

          <Button 
              onClick={tryMigrateEmail} 
              style={{backgroundColor: "#3CD4A0", color: "#ffffff", paddingLeft: "24px",borderRadius: "100px", paddingRight: "24px", fontWeight:"bold", marginTop:"20px"}}
              >{isLoading ? "Loading..." : "Submit"}</Button>

          {message ? <div style={{marginTop: "10px"}}>{message}</div> : null}

          </CardContent>
      </Card>
    </div>
  );
}
