import React from 'react'
import { TextField, makeStyles } from '@material-ui/core'

import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'
import { useValidation } from './validation'

import styles from './styles'

const useStyles = makeStyles(styles)

const CryptoCourseBitPayButtonData = process.env.REACT_APP_CRYPTO_COURSE_BITPAY_BUTTON_DATA;
const CryptoCourseBitPayButtonDataOneDollarTest = process.env.REACT_APP_CRYPTO_COURSE_BITPAY_BUTTON_DATA_ONE_DOLLAR_TEST;
const IsUat = process.env.REACT_APP_ENV?.toLowerCase() === "uat";

export default function StartBitPayStep({ onChangeStep, isTest }) {

    const onSubmit = React.useCallback(() => {
       
        onChangeStep({})
    }, [])

    if(CryptoCourseBitPayButtonData === undefined) {
        onChangeStep({})
    }

    let buttonData = CryptoCourseBitPayButtonData;
    if(isTest) {
        buttonData = CryptoCourseBitPayButtonDataOneDollarTest;
    }

    const classes = useStyles()
    const bitPayUrl = IsUat ? "https://test.bitpay.com/checkout" : "https://bitpay.com/checkout";
    const bitPayButtonUrl = IsUat ? "https://test.bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg" : "https://bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg";
    return (
        <>
            <Product.Content>
            <ProgressButton onClick={onSubmit}>Pay with Credit Card</ProgressButton>
                <div style={{position: "absolute", left: "23%", bottom:"48%"}}>
                <center><b>OR</b></center><br/><br/>
                <form action={bitPayUrl} method="post">
                <input type="hidden" name="action" value="checkout" />
                <input type="hidden" name="posData" value="" />
                <input type="hidden" name="notificationType" value="json" />
                <input type="hidden" name="data" value={buttonData} />
                <input type="image" src={bitPayButtonUrl} name="submit" style={{width:"210px"}} alt="BitPay, the easy way to pay with bitcoins."/>
                </form>
                
                
                </div>
            </Product.Content>
            <Product.Actions>
               
            </Product.Actions>
            
        </>
    )
}