
import React from 'react'

export const ErrorContext = React.createContext(null)

export class ErrorContextProvider extends React.Component {
    static getDerivedStateFromError(error) {
        return {
            error,
            isPersistent: false
        }
    }

    setAsyncError = ({ error, isPersistent = false }) => {
        if(process.env.NODE_ENV === 'development') {
            console.error(error)
        }

        //Sentry.captureException(error)

        this.setState({
            error,
            isPersistent
        })
    }

    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isPersistent: false,
            setAsyncError: this.setAsyncError
        }
    }

    componentDidMount = () => {
        // reset error if not persistent

        this.setState({
            error: this.state.isPersistent ? this.state.error : null
        })
    }

    componentDidCatch = (error, errorInfo) => {
        if(process.env.NODE_ENV === 'development') {
            console.error(error, errorInfo)
        }
        
        // Sentry.withScope(scope => {
        //     scope.setExtras({
        //         errorInfo
        //     })
        //     Sentry.captureException(error)
        // })
    }

    render() {
        return (
            <ErrorContext.Provider value={this.state}>
                {this.props.children}
            </ErrorContext.Provider>
        )
    }
}

export const useErrorContext = () => React.useContext(ErrorContext)
