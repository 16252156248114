import React, { useState, useContext, createContext, useEffect } from "react";
import axios from 'axios';
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import {LoadingSpinner} from "./../components/helpers/LoadingSpinner.js";

import { AppContext } from "./appContext.js";

// Create Context Object
export const UserContext = createContext();

const GET_USER = gql`
  query {
    users {
      id
      twitter_handle
      last_name
      first_name
      email
      updated_at
      created_at
      auth0_id
      stripe_id
      avatar
      name
    }
  }
`;

// Create a provider for components to consume and subscribe to changes
export const UserContextProvider = props => {
  const {accessToken, isLoading, setIsLoading, error, setError} = useContext(AppContext);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);

  const getSubscriptionStatus = () => {
    return axios.get('/.netlify/functions/getSubscriptionStatus?accessToken='+accessToken)
      .then(response => {
        setSubscriptionStatus(response.data.subscriptionStatus);
      })
      .catch(e => {
        setError("There was an error. 10");
      });
  };

  const getTwitterFollowStatus = () => {
    return axios.get('/.netlify/functions/getTwitterFollowStatus?accessToken='+accessToken)
      .then(response => {
        setIsFollowing(response.data.connections.includes("following"));
      })
      .catch(e => {
        setError("There was an error. 11");
      });
  };

  useEffect(() => {
    if(accessToken) {
      setIsLoading(true);
      axios.all([
        getSubscriptionStatus(), 
        //getTwitterFollowStatus()
      ])
      .then(() => {
        setIsLoading(false);
      });
      
    }
  }, [accessToken]) 

  const query = useQuery(GET_USER);
  if(query.error) {
    console.log(query.error);
    return (<div>Error</div>);
  }

  if(isLoading || query.loading) {
    return <div></div>;
  }

  // hasura permissions configured to only return the authenticated user
  const user = query.data.users[0];

  console.log(user);

  return (
    <UserContext.Provider value={{user, subscriptionStatus, isFollowing, getTwitterFollowStatus}}>
      {props.children}
    </UserContext.Provider>
  );
};