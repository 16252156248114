import React, { useEffect } from 'react'
import { TextField, Typography, makeStyles } from '@material-ui/core'
import axios from 'axios'
import { useMutation } from 'react-query'

import { useErrorContext } from './ErrorContext'
import * as Product from './ProductDialog'
import ProgressButton from './ProgressButton'
import TwitterLoginImage from './twitter-login.png'

import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
    login: {
        marginTop: 40,
        maxWidth: 180,
        marginLeft: 'calc(50% - 90px)'
    },
    reopen: {
        textDecoration: 'none',
        color: '#000',
        fontSize: 'small'
    },
    img: {
        width: 'inherit'
    }
})

async function getOAuthUrl() {
    // const url = `${APPS_API_URL}/api/oauth/twitter`
    // const { data } = await axios.post(url, {})
    // return data
}

async function getTwitterUsername(values) {
    // const url = `${APPS_API_URL}/api/oauth/twitter/authorize`
    // const { data } = await axios.post(url, values)
    // return data.username
}

function popup(url) {
    const w = 600
    const h = 400
    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2

    window.open(
        url,
        '',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`
    )
}

export default function TwitterLoginStep({ onChangeStep }) {
    //const { setAsyncError } = useErrorContext()

    const { isLoading, loginWithRedirect, getAccessTokenSilently, isAuthenticated } = useAuth0();

    const [loginState, setLoginState] = React.useState('button')
    const [oauthUrl, setOauthUrl] = React.useState(null)
    const [requestToken, setRequestToken] = React.useState(null)

    useEffect(() => {
        if(isLoading)
        return; 
    
        if(isAuthenticated) {
            onChangeStep({
                metadata: {
                    twitterUsername: ""
                }
            })
        }
    }, [])

    const onLogin = React.useCallback(e => {
        e.preventDefault()
        //loginWithRedirect({connection: 'twitter', audience:"hasura"});

        window.popupCallback = (value) => {
            console.log("tes")
            console.log(value);

            getAccessTokenSilently()

            onChangeStep({
                metadata: {
                    twitterUsername: ""
                }
            })
        }

        popup("/twitter-login");
        
    }, [])

    const classes = useStyles()

    const renderedLoginState = React.useMemo(() => {

        if (isLoading) {
            return <Typography>One moment...</Typography>
        }

        if (loginState === 'button') {
            return (
                <a href="sign-in-with-twitter" onClick={onLogin}>
                    <img src={TwitterLoginImage} className={classes.img} alt="Sign in with twitter" />
                </a>
            )
        }

        
    }, [loginState])

    return (
        <>
            <Product.Content>
                <Product.Description>
                    Sign in with Twitter so that we can authorize your account
                </Product.Description>
                <div className={classes.login}>{renderedLoginState}</div>
            </Product.Content>
            <Product.Actions>
                {/* <ProgressButton
                    canClick={!!pin}
                    onClick={onSubmit}
                    isLoading={isLoading}
                >
                    NEXT
                </ProgressButton> */}
            </Product.Actions>
        </>
    )
}
