import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter, useHistory  } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

// styles
import useStyles from "./styles";

function Login(props) {
  var classes = useStyles();
  const history = useHistory();

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if(isAuthenticated) {
    history.push("/dashboard");
  }

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  return (
    <Grid container className={classes.container}>
      <div>
        <center><img src="/sl_wsj_watermark__white.png" alt="logo" className={classes.logotypeImage} /></center>
        {/* <Typography variant="h1" className={classes.greeting}>
                Get Started
              </Typography> */}
              <Button size="large" className={classes.twitterButton} onClick={() => loginWithRedirect({connection: 'twitter', audience:"hasura"})}>
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23z" fill="#00acee"/></svg>
                &nbsp;Sign in with Twitter
              </Button>
      </div>
     
    </Grid>
  );
}

export default withRouter(Login);