const BaseInputFixOverride = {
    padding: '8px 4px',
    '&:not(.Mui-disabled)::after': { borderColor: '#303030' }
}

//check url param for is_embed
const is_embed = window.location.href.indexOf('is_embed=true') > -1;

const styles = () => ({
    // container
    root: {
        fontFamily: 'Montserrat',
        margin: 0,
        borderRadius: '4px'
    },
    backdrop: {
        backgroundColor: is_embed ? 'white' : null
    },
    dialogPaper: {
        maxWidth: '400px',
        minWidth: '400px',
        minHeight: '500px',
        overflowY: "visible",
        maxHeight: "none",
        boxShadow: is_embed ? "none" : null,
        marginTop: is_embed ? '200px' : null,
    },
    // Header
    header: {
        margin: 0,
        padding: '30px',
        minHeight: '140px',
        textAlign: 'center',
        backgroundImage: 'linear-gradient(143deg, #151515 0%, #303030 100%)',
        borderRadius: '0px 0px 85% 85% / 30%',
        borderLeftWidth: '80%',
        position: is_embed ? 'fixed' : null,
        top: is_embed ? '0' : null,
        left: is_embed ? '0' : null,
        right: is_embed ? '0' : null,
    },
    headerCondensed: {
        minHeight: 'unset'
    },
    headerResponseIcon: {
        marginTop: '20px',
        color: '#FFF',
        fontSize: '100px',
        lineHeight: '110px'
    },
    backButton: {
        position: 'absolute',
        left: '10px',
        top: '10px',
        color: 'grey'
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: 'grey'
    },
    title: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#fff',
        letterSpacing: '0.32px',
        textAlign: 'center',
        lineHeight: '25px'
    },
    price: {
        fontFamily: 'Montserrat',
        fontSize: '45px',
        color: '#fff',
        letterSpacing: '2px',
        textAlign: 'center',
        lineHeight: '50px',
        fontWeight: '600',
        margin: '10px 0'
    },
    priceSmaller: {
        fontSize: 32
    },
    priceStriked: {
        fontSize: '22px',
        color: '#eee',
        letterSpacing: '0.32px',
        textAlign: 'center',
        lineHeight: '25px',
        textDecoration: 'line-through',
        marginRight: '15px'
    },
    subtitle: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#fff',
        letterSpacing: '0.32px',
        textAlign: 'center',
        lineHeight: '25px'
    },
    // Body
    content: {
        padding: '30px',
        overflowY: "visible"
    },
    paragraph: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#151515',
        letterSpacing: '0.32px',
        textAlign: 'center',
        lineHeight: '24px',
        minHeight: '48px',
        fontWeight: '600'
    },
    input: {
        margin: '10px 0',
        ...BaseInputFixOverride
    },
    radioGroup: {
        marginTop: '20px'
    },
    radioContainer: {
        padding: '8px 16px',
        margin: '15px 0 0 0',
        border: '1px solid #C1C1C1',
        borderRadius: '4px'
    },
    radio: {
        color: '#C1C1C1',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#2D75C9'
        }
    },
    couponInput: {
        '& div': {
            padding: '8px 16px',
            border: '1px solid #c1c1c1',
            borderRadius: '4px',
            marginTop: '15px'
        }
    },
    couponInputBtn: {
        borderLeft: '1px solid #c1c1c1',
        position: 'absolute',
        right: '0px',
        bottom: '0',
        top: '0',
        borderRadius: '0',
        padding: '8px 16px'
    },
    couponBtn: {
        fontSize: '16px',
        textTransform: 'none',
        padding: '12px 16px',
        margin: '15px 0',
        border: '1px dashed #C1C1C1',
        borderRadius: '4px'
    },
    billingSubheading: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        letterSpacing: '2.5px',
        fontWeight: '500',
        color: '#FFB900',
        marginTop: '15px'
    },
    billingDetailsSection: {
        display: 'flex',
        marginTop: '10px',
        justifyContent: 'space-between'
    },
    cardNumberContainer: {
        position: 'relative',
        padding: '12px 4px 12px 50px',
        borderBottom: '1px solid #c1c1c1',
        '&:hover': {
            borderBottom: '1px solid #303030'
        }
    },
    cardIcon: {
        position: 'absolute',
        top: '2px',
        bottom: '0',
        left: '0',
        fontSize: '40px',
        color: '#303030'
    },
    cardDetailsContainer: {
        padding: '12px 4px',
        borderBottom: '1px solid #c1c1c1',
        width: '45%',
        '&:hover': {
            borderBottom: '1px solid #303030'
        }
    },
    billingAddress: {
        width: '60%',
        ...BaseInputFixOverride
    },
    billingZip: {
        width: '35%',
        ...BaseInputFixOverride
    },
    billingCity: {
        width: '60%',
        ...BaseInputFixOverride
    },
    billingState: {
        width: '35%',
        ...BaseInputFixOverride
    },
    billingCountry: {
        ...BaseInputFixOverride
    },
    // Footer & CTA
    footer: {
        padding: '30px',
        position: is_embed ? 'fixed' : null,
        bottom: is_embed ? '0' : null,
        left: is_embed ? '0' : null,
        right: is_embed ? '0' : null,
    },
    mailto: {
        color: '#303030'
    }
})

export default styles
